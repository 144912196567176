// extracted by mini-css-extract-plugin
export var globalPartner = "index-module--globalPartner--Ai9vC";
export var title = "index-module--title--y1vh6";
export var second_title = "index-module--second_title--TIaIJ";
export var globalPartner_main = "index-module--globalPartner_main--99skU";
export var shuffling_container = "index-module--shuffling_container--mvTqo";
export var big = "index-module--big--He5w1";
export var shuffling = "index-module--shuffling--l93I1";
export var shuffling_box = "index-module--shuffling_box---8cHM";
export var innerBox = "index-module--innerBox--5soex";
export var spread = "index-module--spread--nsZ7V";