// extracted by mini-css-extract-plugin
export var intro_content = "index-module--intro_content--aNwGq";
export var main = "index-module--main--OH44V";
export var linkSolution = "index-module--linkSolution--qWunO";
export var title = "index-module--title--gsxqR";
export var intro = "index-module--intro--zHfWM";
export var intro_title = "index-module--intro_title--06uud";
export var effectBoxContainer = "index-module--effectBoxContainer--DU5Nb";
export var box = "index-module--box--lWbKK";
export var mask = "index-module--mask--UdxT0";
export var slide = "index-module--slide--sHEdF";
export var showText = "index-module--showText--JOP5B";
export var hiddenText = "index-module--hiddenText--3I65p";
export var link_footer = "index-module--link_footer--cj6As";
export var hover_show = "index-module--hover_show--SmNYw";
export var mobile_item = "index-module--mobile_item--HnDXj";
export var icon = "index-module--icon--VpJY8";