// 全球链路解决方案（首页模块）
import React from "react"

import HeadLine from "@components/Headline"

import * as styles from "./index.module.scss"

import { Col, Row, Collapse } from "antd"
import { UpOutlined } from "@ant-design/icons";
// import { Link } from "gatsby"

const { Panel } = Collapse
const LinkRenderData = [{
    icon: "linkIcon1",
    bg: "linkBg1",
    text_show: '大数据选品系统',
    text_hidden: "吸收海量信息，勾勒市场规律，捕捉供求趋势，预判潜力商品，消除试错成本"
}, {
    icon: "linkIcon2",
    bg: "linkBg2",
    text_show: '程序化创意系统',
    text_hidden: "用数据和算法赋能广告创意，立足H5自动化制作平台，立足海外受众审美，智能生成内容"
}, {
    icon: "linkIcon3",
    bg: "linkBg3",
    text_show: '广告智能投放系统',
    text_hidden: "深挖海外受众个性化标签属性，精准种草，动态优化预算，广告自动启停"
}, {
    icon: "linkIcon4",
    bg: "linkBg4",
    text_show: 'SaaS独立站平台',
    text_hidden: " 借独立站建站平台资历优势，持续输入营销秘术、优化策略，助品牌建稳出海据点，大幅拓展市场"
}, {
    icon: "linkIcon5",
    bg: "linkBg5",
    text_show: 'BI商业智能系统',
    text_hidden: " 智能整合分析业务数据，ROI、AOV等关键数据可视化集成呈现，为重大决策提供有力支撑"
}, {
    icon: "linkIcon6",
    bg: "linkBg6",
    text_show: '风控系统',
    text_hidden: "选品、广告、侵权风险预先管控，增效减损，经营安心"
}, {
    icon: "linkIcon7",
    bg: "linkBg7",
    text_show: '供应链&物流 云服务系统',
    text_hidden: "深谙跨境物流运作体系，甄选高质量服务商，配送轨迹速报，保障稳定时效，销量高峰无忧消化"
}, {
    icon: "linkIcon8",
    bg: "linkBg8",
    text_show: '售后云服务系统',
    text_hidden: "集中管理争议/拒付事件，达成多渠道实时智能回复，告别低效客服，远离投诉"
},]
function GetImg(name: string) {
    return require(`@images/${name}.png`).default
}

interface ImobileItem {
    bg: string;
    text_show: string;
    text_hidden: string;
    icon: string;
}
const MobileItem = ({ bg, text_show, text_hidden, icon }: ImobileItem) => {
    return <div style={{ backgroundImage: `url(${GetImg(bg + '_shadow')})`, backgroundSize: "100% auto" }} className={styles.mobile_item}>
        <div className={styles.icon}>
            <img src={GetImg(icon)} alt="" />
        </div>

        <Collapse ghost expandIconPosition="right"
            expandIcon={({ isActive }) => (
                <UpOutlined rotate={isActive ? 0 : 180} style={{ color: "#9396A3" }} />
            )}
        >
            <Panel header={<span style={{ color: "#fff" }}>{text_show}</span>} key="1">
                <span style={{ color: "#fff" }}>{text_hidden}</span>
            </Panel>
        </Collapse>

    </div>
}

export default function LinkSolution() {

    return <div className={styles.linkSolution}>
        <div className={styles.main}>
            <div className={styles.title}>
                <HeadLine>全链路解决方案</HeadLine>
            </div>
            <div className={styles.intro}>
                <div className={styles.intro_title}>预先抢占商机，始终快人一步</div>
                <div className={styles.intro_content}>
                    从大数据精筛选品，到零经验无障碍建店；从多元化智能营销，到高时效物流供应链……无论线上操作，还是线下运作，Fancyint都已提前为您考虑一切细节，打通所有环节，真正做到降本、避险、增收，助力您的品牌无忧出海，勇往直前！
                </div>
            </div>
            <Row>
                <Col md={24} sm={0} xs={0}>
                    {/* pc */}
                    <div className={styles.effectBoxContainer}>
                        {LinkRenderData.map((item, index) => {
                            return <div className={styles.box} key={index}>
                                <img src={GetImg(item.bg)} alt="linkIcon" />
                                <div className={styles.mask}></div>
                                <div className={styles.slide}>
                                    <img src={GetImg(item.icon)} alt="icon" />
                                    <div className={styles.showText}>{item.text_show}</div>
                                    <div className={styles.hiddenText}>{item.text_hidden}</div>
                                </div>
                            </div>
                        })}

                    </div>
                    <div className={styles.link_footer}>
                        <div className={styles.slide}>
                            <img src={GetImg("linkBook")} alt="linkBook" /> Fancyint智库 <span className={styles.hover_show}>更全面的行业新知传递平台，更为通俗易懂的基础铺垫，更深度的进阶实操指南</span>
                        </div>
                    </div></Col>
                <Col md={0} sm={24} xs={24}>
                    <div style={{ marginTop: "40px" }}>
                        {
                            LinkRenderData.map((item, index) => <MobileItem {...item} key={index} />
                            )
                        }
                    </div>
                </Col>
            </Row>
        </div>
    </div >
}