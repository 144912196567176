import React from "react"

import Helmet from "react-helmet"

//引入各个部分的组件
import Banner from "@components/Banner"
import Brand from "@components/Brand"
import LinkSolution from "@components/LinkSolution"
import GlobalPartner from "@components/GlobalPartner"
import PlatForm from "@components/PlatForm"
import Consultation from "@components/Consultation"

import { Col, Row, Button } from "antd"

import * as styles from "./index.module.scss"
import "antd/dist/antd.css"
export interface Ipage {
  path: string,
  [prop: string]: any
}

export default function Index(params: Ipage) {
  return <div className={styles.index}>
    <Helmet title='首页-Fancyint' />

    {/* 首页banner */}
    <Banner />

    {/* 品牌实力板块 */}
    <Brand />

    {/* 汇集海外力量 */}
    <PlatForm />

    {/* 全链路解决方案 */}
    <LinkSolution />

    {/* 全球伙伴资源 */}
    <GlobalPartner />

    {/* <Consultation /> */}
  </div>
}
