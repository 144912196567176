//全球伙伴资源（首页模块）
import React, { useEffect, useRef } from "react";

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import HeadLine from "@components/Headline";

import { Row, Col } from "antd";

import * as styles from "./index.module.scss"

const QueenOne = [
    "partner1", "partner2", "partner3", "partner4", "partner5", "partner6", "partner7", "partner8", "partner9", "partner10", "partner11", "partner12"
]

const QueenTwo = [
    "partner13", "partner14", "partner15", "partner16", "partner17", "partner18", "partner19",
]

function GetImg(name: string) {
    return require(`@images/${name}.png`).default
}

export default function GlobalPartner() {
    const data = useStaticQuery(graphql`query partnerQuery {
  spread:file(relativePath: {eq: "spread.png"}) {
    childImageSharp {
      gatsbyImageData(quality:80,formats:WEBP)
    }
  }
}`)
    let timer: any;
    const shufflingBox = useRef<HTMLDivElement>(null)
    const shufflingBox2 = useRef<HTMLDivElement>(null)
    function move() {
        if (shufflingBox.current) {
            let left = shufflingBox.current.style.left
            // console.log(parseInt(left));
            left = `${parseFloat(left) - 0.01}%`
            shufflingBox.current.style.left = left
            if (parseFloat(left) < -200) {
                shufflingBox.current.style.left = "-100%"
            }
        }
        if (shufflingBox2.current) {
            let left = shufflingBox2.current.style.left
            // console.log(parseInt(left));
            left = `${parseFloat(left) + 0.01}%`
            shufflingBox2.current.style.left = left
            if (parseFloat(left) > -100) {
                shufflingBox2.current.style.left = "-200%"
            }
        }
    }
    useEffect(() => {
        timer = setInterval(move, 10)
        return () => {
            clearInterval(timer)
        }
    }, [])

    const image = getImage(data.spread.childImageSharp.gatsbyImageData)
    return <div className={styles.globalPartner}>
        <div className={styles.title}>
            <HeadLine>全球伙伴资源</HeadLine>
        </div>
        <Row>
            <Col md={24} sm={0} xs={0}>
                <div className={styles.second_title}>您的选择，也是跨境精英共同的信赖</div>
            </Col>
            <Col md={0} sm={24} xs={24}>
                <div className={styles.second_title}>
                    您的选择，
                    <br />
                    也是跨境精英共同的信赖</div>
            </Col>
        </Row>
        <div className={styles.globalPartner_main}>
            <div className={styles.shuffling_container}>
                <div className={styles.big}>
                    <div className={styles.shuffling} ref={shufflingBox} style={{ left: "-100%" }}>
                        {new Array(4).fill(null).map((_, index) =>
                            <div className={styles.shuffling_box} key={index}>
                                {
                                    QueenOne.map((item, index) =>
                                        <div className={styles.innerBox} key={index}>
                                            <img src={GetImg(item)} alt="img" />
                                        </div>)
                                }
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.big}>
                    <div className={styles.shuffling} ref={shufflingBox2} style={{ left: "-200%" }}>
                        {new Array(4).fill(null).map((_, index) =>
                            <div className={styles.shuffling_box} key={index}>
                                {
                                    QueenTwo.map((item, index) =>
                                        <div className={styles.innerBox} key={index}>
                                            <img src={GetImg(item)} alt="img" />
                                            {/* <h1>{index}</h1> */}
                                        </div>)
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Row>
                <Col md={24} sm={0} xs={0}>
                    <div className={styles.spread}>
                        {image && <GatsbyImage image={image} alt="spread" />}
                    </div>
                </Col>
            </Row>

        </div>
    </div>
}