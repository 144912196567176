// 汇聚海外流量板块 (首页模块)
import React, { ReactNode } from "react"

import HeadLine from "@components/Headline"

import platformBg1 from "@images/platformBg1.png"
import platformBg2 from "@images/platformBg2.png"
import platformBg3 from "@images/platformBg3.png"
import platformBg4 from "@images/platformBg4.png"

import * as styles from "./index.module.scss"

interface IplatFormItem {
    children: ReactNode | string
    img: any
}

function PlatFormItem({ children, img }: IplatFormItem) {
    return <div className={styles.platform_item}>
        <img src={img} alt="" />
        <div className={styles.text}>
            {children}
        </div>
    </div>

}



export default function PlatForm() {
    return <div className={styles.platform}>
        <HeadLine>汇聚海外流量</HeadLine>
        <div className={styles.second_title}>专业顾问，高效开户</div>
        <div className={styles.main}>
            <PlatFormItem img={platformBg1}>全球27亿用户</PlatFormItem>
            <PlatFormItem img={platformBg2}>每人每天<br />搜索10次</PlatFormItem>
            <PlatFormItem img={platformBg3}>每日浏览<br />10亿次</PlatFormItem>
            <PlatFormItem img={platformBg4}> 每月搜索<br />10亿次</PlatFormItem>
        </div>
    </ div>
}