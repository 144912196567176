//品牌实力（首页模块）
import React from 'react'

import { Col, Row, Carousel } from "antd"

import HeadLine from '@components/Headline'

import BrandIcon1 from "@images/brandIcon1.png"
import BrandIcon2 from "@images/brandIcon2.png"
import BrandIcon3 from "@images/brandIcon3.png"
import BrandIcon4 from "@images/brandIcon4.png"

import * as styles from "./index.module.scss"


const BrandData = [
    {
        icon: BrandIcon1,
        showData: "10",
        suffix: "年",
        textBold: "海外营销深耕资历",
        textNormal: "让项目的每个细节，都写满“专业”"
    },
    {
        icon: BrandIcon2,
        showData: "200",
        suffix: "+",
        textBold: "产品触达国家或地区",
        textNormal: "积好评如潮，汇口碑江海"
    },
    {
        icon: BrandIcon3,
        showData: "43.82",
        suffix: "%",
        textBold: "旗下用户年收益增长",
        textNormal: "我们倾尽全力，只为提升您的实力"
    },
    {
        icon: BrandIcon4,
        showData: "TOP1",
        textBold: "业内核心工具顶级研发技术",
        textNormal: "心有征途，才能创造不息，一往无前"
    }
]

interface ItemType {
    icon: any,
    showData: string;
    textBold: string;
    textNormal: string;
    suffix?: string;
}

function BrandItem({ icon, showData, textBold, textNormal, suffix }: ItemType) {
    return <div className={styles.brand_item}>
        <img src={icon} alt="brandIcon" />
        <div className={styles.data_show}><span>{showData}</span>
            {suffix && <small>{suffix}</small>}
        </div>
        <div className={styles.bold}>{textBold}</div>
        <div className={styles.normal}>{textNormal}</div>
    </div>
}
function Card({ icon, showData, textBold, textNormal, suffix }: ItemType) {
    return <div style={{ height: "434px", paddingTop: "46px" }}>
        <div className={styles.card}>
            <img src={icon} alt="brandIcon" />
            <div className={styles.data_show}><span>{showData}</span>
                {suffix && <small>{suffix}</small>}
            </div>
            <div className={styles.bold}>{textBold}</div>
            <div className={styles.normal}>{textNormal}</div>
        </div>
    </div>
}
export default function Brand() {
    return <div className={
        styles.brand
    }>
        <div className={styles.title}>
            <HeadLine>品牌实力</HeadLine>
        </div>
        <Row>
            {/* pc */}
            <Col md={24} sm={0} xs={0}>
                <div className={styles.brand_main}>
                    {
                        BrandData.map(item => <BrandItem {...item} key={item.textNormal} />)
                    }
                </div>
            </Col>
            <Col md={0} sm={24} xs={24}>
                < Carousel style={{ overflow: "visible" }}>
                    {
                        BrandData.map(item => <Card {...item} key={item.textNormal} />)
                    }
                </Carousel>
            </Col>
        </Row>

    </div>
}